export function phoneValidation(value) {
  if (!value) return false;

  if (!value.startsWith('+55'))
    return /^\+\d{10,14}$/g.test(value.replaceAll(' ', ''));

  const phone = value.replace('+55', '').replace(/\D/g, '');

  if (isKnownInvalidNumber(phone)) return false;

  if (isInvalidLength(phone)) return false;

  if (isInvalidCellphone(phone)) return false;

  if (isAllSameDigit(phone)) return false;

  if (isInvalidDDD(phone)) return false;

  return true;
}

export function phoneDestructuring(phone) {
  const result = { fullNumber: phone };
  const countryCode = phone.slice(0, 3);
  const areaCode = phone.slice(4, 6);
  const number = phone.slice(7, 19);

  if (countryCode.length <= 4) result.countryCode = countryCode;

  if (areaCode.length <= 3) result.areaCode = areaCode;

  if (number.length <= 12) result.number = number.replace(' ', '-');

  return result;
}

export function formatPhone(value) {
  const matchWithDDI = value.match(/(\d{2})(\d{2})(\d{4})(\d{4})/);
  const matchWithoutDDI = value.match(/(\d{2})(\d{4})(\d{4})/);

  if (matchWithDDI)
    return `+${matchWithDDI[1]} ${matchWithDDI[2]} ${matchWithDDI[3]}-${matchWithDDI[4]}`;

  if (matchWithoutDDI)
    return `(${matchWithoutDDI[1]}) ${matchWithoutDDI[2]}-${matchWithoutDDI[3]}`;

  return value;
}

function isAllSameDigit(phone) {
  let result = true;

  for (let n = 0; n < 10 && result; n++) {
    if (phone === new Array(11).join(n) || phone === new Array(12).join(n))
      result = false;
  }

  return !result;
}

function isInvalidDDD(phone) {
  const DDD = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  ];

  return DDD.indexOf(parseInt(phone.substring(0, 2), 10)) === -1;
}

function isInvalidCellphone(phone) {
  return phone.length === 11 && parseInt(phone.substring(2, 3), 10) !== 9;
}

function isInvalidLength(phone) {
  return !(phone.length === 11);
}

function isKnownInvalidNumber(phone) {
  const knownInvalidNumbers = [
    '000000000',
    '098765432',
    '111111111',
    '123456789',
    '222222222',
    '234567890',
    '333333333',
    '444444444',
    '555555555',
    '666666666',
    '777777777',
    '888888888',
    '900000000',
    '911111111',
    '922222222',
    '933333333',
    '944444444',
    '955555555',
    '966666666',
    '977777777',
    '987654321',
    '988888888',
    '999999999',
  ];

  return knownInvalidNumbers.includes(phone.substring(2, 11));
}
